$(document).ready(() => {
  let wWidth = $(window).width();
  let navState = false;
  const $header = $('.page-header');
  const $links = $header.find('.page-header__nav ul a');
  let isObserver = true;
  let observer;
  // let linksObserver;

  if (
    !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('isIntersecting' in window.IntersectionObserverEntry.prototype)
  ) {
    isObserver = false;
    $('html').removeClass('is-observer');
  }

  if (isObserver) {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('is-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '0px 0px -15% 0px' },
    );
  }

  function isTouchDevice() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query) => {
      return window.matchMedia(query).matches;
    };

    if (
      'ontouchstart' in window ||
      // eslint-disable-next-line no-undef
      (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
      '',
    );
    return mq(query);
  }

  if (isTouchDevice()) {
    $('html').addClass('is-touch');
  }

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate, ...args) {
    let timeout;
    return function () {
      const context = this;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function disableScrolling() {
    if ($(document).height() > $(window).height()) {
      const scrollTop = $('html').scrollTop()
        ? $('html').scrollTop()
        : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
      $('html').addClass('disable-scrolling').css('top', -scrollTop);
    }
  }

  function enableScrolling() {
    const scrollTop = parseInt($('html').css('top'), 10);
    $('html').removeClass('disable-scrolling');
    $('html,body').scrollTop(-scrollTop);
  }

  function bindEvents() {
    $('.hamburger').on('click', () => {
      if (navState) {
        $header.removeClass('is-opened');
        enableScrolling();
      } else {
        $header.addClass('is-opened');
        disableScrolling();
      }

      navState = !navState;
    });

    // header links
    $links.on('click', (e) => {
      e.preventDefault();

      const hash = $(e.currentTarget).attr('href');
      const $target = $(hash);

      if ($target.length) {
        $('html,body').animate(
          {
            scrollTop: $target.offset().top - 150,
          },
          750,
        );

        if (navState && wWidth < 1024) {
          $header.removeClass('is-opened');
          enableScrolling();
          navState = !navState;
        }
      }
    });

    $('.hero__scroll').on('click', () => {
      $('html,body').animate(
        {
          scrollTop: $(window).height(),
        },
        750,
      );
    });

    // FOCUS STYLING
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', () => {
      document.body.classList.remove('is-tab');
    });

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', (event) => {
      if (event.key === 'Tab') {
        document.body.classList.add('is-tab');
      }
    });

    $('form').on('submit', (e) => {
      e.preventDefault();
      const $form = $(e.currentTarget);

      $form.addClass('is-loading');

      if (
        $form.find('#password').val() ||
        $form.find('#password').val().length
      ) {
        return;
      }

      $.ajax({
        method: 'POST',
        dataType: 'json',
        url: './mail.php',
        data: {
          name: $form.find('#first-name').val(),
          email: $form.find('#email').val(),
          phone: $form.find('#phone').val(),
          message: $form.find('#message').val(),
        },
        success(response) {
          $form.removeClass('is-loading');
          if (response.status === 'success') {
            $form.addClass('is-success');
          } else {
            $form.addClass('is-error');
          }

          setTimeout(() => {
            $form.removeClass('is-success is-error');
          }, 3000);
        },
      });
    });
  }

  const doneResizing = debounce(() => {
    const width = $(window).width();

    if (wWidth !== width) {
      wWidth = width;
    }
  }, 500);

  function initSplash() {
    const $block = $('.splash');
    const $hero = $('.hero');

    if (!$block.length) {
      $hero.addClass('is-visible');
    } else {
      const $btn = $block.find('button');

      // particles
      particlesJS.load('particles', './particles.json', () => {
        console.log('callback - particles-js config loaded');
      });

      disableScrolling();

      $btn.on('click', () => {
        enableScrolling();
        $block.addClass('is-hidden');
        $hero.addClass('is-visible');
      });
    }
  }

  /* FUNCTION CALLS */
  /* ============= */
  bindEvents();
  initSplash();

  if (isObserver) {
    $('.js-visibility').each((i, el) => {
      observer.observe(el);
    });
  }

  $(window).on('resize', doneResizing);
});
